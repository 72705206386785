.features-card {
    margin-bottom: 2rem;
  &_header {
    margin-bottom: 1rem;
    font-size: 4.5rem;
  }
  &_title {
    font-size: 1.125rem;
    color: #1a1a1a;
  }
  &_description {
    font-size: 14px;
    color: #a5a3a3;
    line-height: 1.5em;
  }
  .section1 {
    color: #e54485;
  }
  .section2 {
    color: #eca507;
  }
  .section3 {
    color: #02d99a;
  }
  .section4 {
    color: #52a2f9;
  }
  .section5 {
    color: #e54485;
  }
  .section6 {
    color: #eca507;
  }
  .section7 {
    color: #02d99a;
  }
}
