.feedback-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  &_header {
    text-align: center;
    &_img {
      height: 5rem;
      margin-bottom: 2rem;
    }
    &_title {
      color: #191919;
      font-size: 18px;
      font-family: "29LT Bukra";
      font-weight: bold;
      text-align: center;
      line-height: 1.75rem;
      margin-bottom: 2rem;
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_group {
        margin-bottom: 1.75rem;
      &_label {
        color: #5e5873;
        font-family: "29LT Bukra";
        font-size: 0.875rem;
        font-weight: bold;
      }
      &_input-wrapper {
        &_icon {
          border: 1px solid #d8d6de;
          padding: 0.35rem;
          border-bottom-left-radius: 0.357rem;
          border-top-left-radius: 0.357rem;
          color: #c0c0c0;
          width: 2.5rem;
          justify-content: center;
          align-items: center;
          svg {
            width: 1.5rem;
          }
        }
        &_input {
          font-family: "29LT Bukra";
          font-size: 0.875rem;
          font-weight: bold;
        }
      }
    }
    &_btn {
      border-radius: 5px;
      background: linear-gradient(188.59deg, #b7016e 0%, #630a5d 100%);
      width: 50%;
    }
  }
}
