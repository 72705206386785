@font-face {
  // font-family: "29LT Bukra";
  // src: local("29LT Bukra"),
  //   url("./../../assets/data/fonts/29ltbukrabold.otf")
  //     format("truetype");
  font-family: "29LT Bukra";
  src: url("./../../assets/data/fonts/29ltbukrabold.otf") format("truetype");
  font-weight: bold;
}

.landing-page-wrapper {
  background-color: #fff;
  direction: rtl;
  * {
    // font-family: "29LT Bukra" !important;
    font-family: "Cairo", sans-serif;
  }
  .section {
    margin-bottom: 3rem;
  }
  .section-title {
    color: #191919;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    text-align: right;
  }
  .default-section-wrapper {
    padding: 2rem;
    &_content {
      &_body {
        color: #8a898b;
        font-weight: bold;
        margin-bottom: 2rem;
        text-align: right;
      }
    }
    &_img-wrapper {
      &_img {
        max-width: 100%;
      }
    }
  }
  .gradient-btn {
    border-radius: 1.5rem;
    background: linear-gradient(183.97deg, #b7016e 0%, #630a5d 100%);
    color: #fff;
  }
}
