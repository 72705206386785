.contact-us-wrapper {
    margin-bottom: 5rem;
    &_header {
    margin-bottom: 3rem;
    p {
      color: #a5a3a3;
      line-height: 1.5em;
      text-align: center;
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    &_input {
      border: unset;
      border-radius: unset;
      border-bottom: 1px solid #9d9c9c;
      padding: 2rem 0.5rem;
      margin-bottom: 0.5rem;
    }
    &_submit-btn {
      background-color: #fa5b77;
      color: #fff;
      border: unset;
      border-radius: 2rem;
      padding: 0.75rem 1.5rem;
    }
  }
}
select{
height: 30px;
width: 100%;
border-radius: 3px;
border: 1px solid #d8d6de;
}