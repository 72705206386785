.download-app-wrapper {
    padding: 5rem 2rem;
  &_btn {
    width: 15rem;
    padding: 0.25rem;
    margin: 0.25rem;
    &_img {
      width: 100%;
    }
  }
}
