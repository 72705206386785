.policy-wrapper {
  line-height: 5rem;
  font-family: sans-serif !important;
  .section-title {
    font-size: 35px;
    line-height: 60px;
  }
  .generalLineheight {
    line-height: 40px;
  }
  h2 {
    // font-size: 32px;
    line-height: 48px;
  }
  .body {
    font-size: 1.2rem;
    // color: #8a898b;
    line-height: 1.5em;
    // margin-bottom: 1.5em;
  }
}
