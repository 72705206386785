.btn-play {
  color: #fff;
  background: linear-gradient(#fdcf3e, #f2963e);
  padding: 1rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 12rem;

  &_icon {
    width: 5rem;
    height: 5rem;
  }
}

.about-wrapper {
  &_card {
    padding: 1rem;

    &_img {
      width: 6.25rem;
      height: 6.25rem;
      margin-bottom: 2rem;
    }

    &_title {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
      margin-bottom: 1.5rem;
    }

    &_body {
      font-size: 14px;
      color: #8a898b;
      line-height: 1.5em;
      margin-bottom: 1.5em;
    }
  }
}

p {
  line-height: 1.6
}