.footer {
  background-color: #0f0e0e;
  padding: 0 !important;
  &_img {
    background-image: url("../../../assets/images/pages/landing-page/footer-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 14rem;
  }
  &_content {
    padding: 5rem 0rem;
    &_logo {
      width: 11.875rem;
      height: 6rem;
    }
    &_col {
      display: flex;
      justify-content: center;
      align-items: center;
      &_social-link {
        padding: 0.75rem;
        border-radius: 50%;
        background-color: #1151d3;
        margin: 0.5rem;
        &:hover {
            background-color: #343434;
        }
        img {
          width: 1.75rem;
        }
        svg {
          color: #fff;
        }
      }
    }
  }
}
