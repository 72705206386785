.features-wrapper {
  padding: 4rem;
  background-image: url("../../../assets/images/pages/landing-page/features-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f9f9f9;
  .features-header {
    padding: 2rem;
    flex-direction: column;
    &_description {
      color: #191919;
      font-size: 1.1rem;
    }
  }
  .features-content {
    &_img-wrapper {
      text-align: end;
      padding-left: 5rem;
      padding-right: 5rem;
      img {
        height: 100%;
      }
    }
    &_details-wrapper {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}
