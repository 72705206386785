//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Color system

$white: #fff !default;
$gray-50: #f6f6f6 !default;
$gray-100: #babfc7 !default; // $gray-lightest
$gray-200: #ededed !default; // $gray-lighter
$gray-300: #dae1e7 !default; // $gray-light
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #b8c2cc !default; // $gray
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default; // $gray-dark
$gray-900: #2a2e30 !default;
$black: #22292f !default; // 231f48 22292f

$purple: #7367f0 !default; //$primary
$green: #28c76f !default; //$success
$blue: #00cfe8 !default; //$info
$orange: #ff9f43 !default; //$warning
$red: #ea5455 !default; //$danger

$primary: rgba(109, 74, 172, 1) !default;
$secondary: #82868b !default;
$info: $blue !default;
$warning: $orange !default;
$light: $gray-50 !default;
$dark: #4b4b4b !default;
$error: #be1f1f !default;

$body-bg: #f8f8f8 !default;
$body-color: #6e6b7b !default;

$switch-off-bg-color: #e2e2e2 !default;
$switch-on-bg-color: #5e5873 !default;

$link-color: $primary !default;
$link-hover-color: darken($link-color, 5%) !default;
$link-hover-decoration: none !default;
$custom-control-border-color: #d8d6de;
$table-bg-color: #f3f2f7;
$text-muted: #b9b9c3;

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    25: (
      $spacer * 0.25
    ),
    50: (
      $spacer * 0.5
    ),
    75: (
      $spacer * 0.75
    ),
    "q": (
      $spacer * 0.25
    ),
    "h": (
      $spacer * 0.5
    ),
    1: (
      $spacer
    ),
    2: (
      $spacer * 1.5
    ),
    3: (
      $spacer * 3
    ),
    4: (
      $spacer * 3.5
    ),
    5: (
      $spacer * 4
    )
  ),
  $spacers
);
