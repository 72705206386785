.signup-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  &_header {
    text-align: center;
    margin-bottom: 2rem;
    &_img {
      height: 5rem;
      margin-bottom: 3rem;
    }
    &_title {
      font-family: "29LT Bukra";
      color: #5e5873;
      font-size: 29px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 35px;
      margin-bottom: 1.5rem;
    }
    &_sub-title {
      font-family: "29LT Bukra";
      color: #4b2f7e;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_section {
      margin-bottom: 2rem;
      &_title {
        color: #8a2377;
        font-family: "29LT Bukra";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        margin-bottom: 1.5rem;
      }
      &_img {
        width: 100%;
        border-radius: 0.25rem;
      }
      &_uploader {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        &_label {
          background-color: #8a2377;
          padding: .4rem 1.1rem;
          color: #fff;
          font-size: 1rem;
          border-radius: .25rem;
          cursor: pointer;
          margin-bottom: 1rem;
        }
      }
    }
    &_group {
      margin-bottom: 1.75rem;
      &_label {
        color: #5e5873;
        font-family: "29LT Bukra";
        font-size: 0.875rem;
      }
      &_input {
        font-family: "29LT Bukra";
        font-size: 0.875rem;
      }
    }
    &_btn {
      border-radius: 5px;
      background: linear-gradient(188.59deg, #b7016e 0%, #630a5d 100%);
      width: 50%;
    }
    .terms {
      input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
        &:checked {
          background-color: #8a2377;
        }
      }
      span.signinlink{
        cursor: pointer;
      }
    }
  }
}
