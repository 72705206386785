.banner {
  background-size: cover;
  background-image: url("../../../assets/images/pages/landing-page/header-bg.png");
  .nav-wrapper {
    padding: 2rem;
    &_brand {
      width: 9rem;
      img {
        width: 100%;
      }
    }
    &_ul {
      &_item {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &_link {
          color: #191919 !important;
          font-size: 0.875rem;
          font-weight: bold;
          line-height: 12px;
        }
      }
      &_sign-up {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        a {
          color: #fff !important;
          font-weight: bold;
          padding: 0.5rem 1rem !important;
          font-size: 0.875rem;
        }
      }
    }

  }
  .header-wrapper {
    padding: 2rem;
    &_content {
      color: #191919;
      font-weight: bold;
      text-align: right;
      &_rtbli {
        font-size: 2.5rem;
        color: #191919;
        margin-bottom: 1.5rem;
      }
      &_download {
        display: flex;
        &_btn {
          width: 15rem;
          padding: 0.25rem;
          margin: 0.25rem;
          img {
            width: 100%;
          }
        }
      }
    }
    &_hero {
      &_img {
        width: 26rem;
        position: relative;
        bottom: 3rem;
      }
    }
  }
}
